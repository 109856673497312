import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from './utils/routes';

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

export default function App() {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>   
                    {routes.map(({ path, Component }) => (
                        <Route key={path} path={path} element={<Component />} />
                    ))} 
                </Routes>
            </Suspense>
        </Router>
    );
}