import React from 'react';

export const routes = [
    {
        path: '/',
        name: 'About Us',
        Component: React.lazy(() => import('../pages/Home/Home')),
    },
    {
        path: '/catalogue',
        name: 'Products',
        Component: React.lazy(() => import('../pages/Catalogue/Catalogue')),
    },
    {
        path: '/contact',
        name: 'Contact',
        Component: React.lazy(() => import('../pages/Contact/Contact')),
    },
    {
        path: '/product/:id',
        name: 'Product',
        Component: React.lazy(() => import('../pages/Product/Product')),
    },
    {
        path: '/product/:id/customize/samples',
        name: 'Customizer',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    {
        path: '/product/:id/customize/offer',
        name: 'Customizer',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    // {
    //     path: '/*',
    //     name: 'NoPage',
    //     Component: React.lazy(() => import('../pages/NoPage/NoPage')),
    // },
];